.offerTag {
    font-size: 12;
    background-color: red;
    color: #fff;
    padding-inline: 5px;
    text-align: center;
    font-family: 'BeVietnamPro-Regular';
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.quantityWrapperProductList {
    display: flex;
    flex-direction: row;
    border: 0.4px solid #00000032;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    width: 6rem;
    padding-block: 0.1rem;
}

.cartWrapperProductList {

    padding-inline: 0.9rem;
    padding-block: 0.3rem;
    border: 0.4px solid #00000032;
    justify-content: center;
    align-items: center;
    justify-content: center;
    border-color: #00000032;
    border-radius: 3px;
cursor: pointer;
}



.listWrapper {
    display: flex;
    flex: 1;
    border-top: 0.1px solid #00000032;
    padding-block: 1rem;
    margin-block: 1rem;
    align-items: center;
    position: relative;
}

.product-list-container {
    margin-inline: 1rem;
}

.cart-edit {
    align-self: flex-end;
    position: absolute;
    right: 0
}

.product-name {
    font-family: 'Poppins-Regular';
}

.product-details-wrapper {
    margin-left: 1rem;
    display: flex;
    flex: 2;
    flex-direction: column;
}

@media only screen and (min-width:992px) {
    .listWrapper {
        display: flex;
        flex-direction: column;
        border: 0.1px solid #00000032;
        margin-inline: 1rem;
        border-radius: 7px;
        min-width: 180px;
        max-width: 200px;
    }

    .product-list-container {
        display: flex
    }

    .cart-edit {
        align-self: center;
        position: relative;
    }

    .product-name {
        margin-top: 1rem;
    }
    .product-details-wrapper {
        margin-left: 0;
        width: 100%;
        padding: 10px;
    }
}